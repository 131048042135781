@charset "utf-8";
/* ===================================

    agreement page

=================================== */
#page_help_agreement {
	.contentRight {
.agreement_ttl {
    font-size: 1.4rem;
    margin-bottom: 20px;
    font-weight: bold;
    letter-spacing: 1.57px;
    @include mq-down {
    font-size: 1.2rem;
    }
}
.agreement_list01 {
    margin-bottom: 60px;
    @include mq-down {
    margin-bottom: 30px;
    }
li {
    padding-left: 1.4em;
    text-indent: -1.4em;
    margin-bottom: 10px;
    line-height: 1.6;
    letter-spacing: 1.57px;
    font-weight: bold;
    @include mq-down {
    font-weight: normal;
    }
.agreement_list02 {
    margin: 10px 0 0;
li {
    padding-left: 1.6em;
    text-indent: -1.56em;
}
}
}
}
p {
    margin-bottom: 60px;
    @include mq-down {
    margin-bottom: 30px;
    }
+ ul.agreement_list01 {
    margin-top: -40px;
    @include mq-down {
    margin-top: -10px;
    }
}
}
}
}

.ec-registerRole {
    padding-left: 0;
    padding-right: 0;
.ec-off4Grid.justify-content-center div {
    width: 100%;
.ec-checkbox {
    width: auto;
    text-align: center;
    margin-bottom: 30px;
}
.ec-blockBtn--action {
    margin-bottom: 16px;
    float: left;
    width: 49%;
}
a.ec-blockBtn--cancel {
    float: right;
    width: 49%;
}
}
}