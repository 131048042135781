@charset "utf-8";
/* ===================================

    other page

=================================== */

.ec-registerRole {
  padding-left: 0;
  padding-right: 0;
  .ec-off4Grid.justify-content-center div {
    width: 100%;
    .ec-checkbox {
      width: auto;
      text-align: center;
      margin-bottom: 30px;
    }
    .ec-blockBtn--action {
      margin-bottom: 16px;
      float: left;
      width: 49%;
    }
    a.ec-blockBtn--cancel {
      float: right;
      width: 49%;
    }
  }
}

/*　特商法ページ
------------------------------------------*/
#page_help_tradelaw {
    .contentRight {
        a {
            text-decoration: underline;
        }
    }
}

/*　ご利用ガイドページ
------------------------------------------*/
%bigheading {
  background: #222222;
  color: #fff;
  font-size: 20px;
  letter-spacing: 1.57px;
  padding: 13px 30px;
  margin: 0 0 50px;
  border: none;
}
.ec-pageHeading {
  h2,h3 {
    @extend %bigheading;
  }
}
.ec-subHeading {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 8px;
}
.ec-contentBlock {
  margin: 0 0 15px;
  padding-left: 1em;
}
.ec-contentBlock__text {
  font-size: 14px;
  line-height: 1.7;
  color: #444;
  padding-left: 1em;
}
.ec-contentBlock__list {
  font-size: 14px;
  line-height: 1.7;
  color: #444;
  padding-left: 2em;
  text-indent: -1em;
}
.ec-contentBlock__list li {
  margin-bottom: 5px;
}