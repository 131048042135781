//main:../style.scss
/* ===================================

    sidebar

=================================== */
.sidebar {
  @include mq-up {
    // max-width: 27.2727%;
    // flex: 1 0 27.2727%;
    // max-width: 300px;
    // padding-right: 2.72727%;
    margin-right: 30px;
    width: 270px;
  }
  @include mq-down {
    max-width: 100%;
    margin-top: 20px;
    order: 2;
    display: flex;
    flex-wrap: wrap;
  }
}

.sideBnr {
  display: block;
  margin-bottom: 15px;
  // @include mq-up {
  //   width: 270px;
  // }
  @include mq-down {
    max-width: 50%;
    flex: 1 0 50%;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 10px;
  }
}

.sideCategoryNav {
  $parent: &;
  width: 100%;
  margin-bottom: 15px;
  @include mq-down {
    order: 1;
  }

  &__title {
    @include fontsize(20);
    font-weight: 700;
    background-color: #333;
    color: #ffffff;
    padding: 10px;
    text-align: center;
  }
  &__item {
    position: relative;
    > a {
      border-bottom: 1px solid #DEDEDE;
      background-position: 10px 50%;
      background-repeat: no-repeat;
      background-size: 50px;
      position: relative;
    }
    &--cat1 > a {
      background-image: url(../../src/images/category/icn-cat01.png);
    }
    &--cat2 > a {
      background-image: url(../../src/images/category/icn-cat02.png);
    }
    &--cat3 > a {
      background-image: url(../../src/images/category/icn-cat03.png);
    }
    &--cat4 > a {
      background-image: url(../../src/images/category/icn-cat04.png);
    }
    &--cat5 > a {
      background-image: url(../../src/images/category/icn-cat05.png);
    }
    &--cat6 > a {
      background-image: url(../../src/images/category/icn-cat06.png);
    }
    &--cat7 > a {
      background-image: url(../../src/images/category/icn-cat07.png);
    }
    &--cat8 > a {
      background-image: url(../../src/images/category/icn-cat08.png);
    }
    &--cat9 > a {
      background-image: url(../../src/images/category/icn-cat09.png);
    }
    &--cat10 > a {
      background-image: url(../../src/images/category/icn-cat10.png);
    }
    &--cat11 > a {
      background-image: url(../../src/images/category/icn-cat11.png);
    }
    &--cat12 > a {
      background-image: url(../../src/images/category/icn-cat12.png);
    }
    &--cat13 > a {
	background-image: url(../../src/images/category/icn-cat13.png);
		@include mq-down {
			pointer-events: none;
		}
    }
    &--cat14 > a {
	background-image: url(../../src/images/category/icn-cat14.png);
		@include mq-down {
			pointer-events: none;
		}
	}
	.sideCategoryNav__child {
		background: #222222;
		width: 0;
		max-height: 0;
		padding: 0 30px;
		// transition: width 0.4s;
		position: absolute;
		left: 100%;
		top: 0%;
		overflow: hidden;
		@include mq-down {
			position: static;
			// transition: max-height 0.4s;
			&.is-visible {
				position: static;
			}
		}
		a {
			color: #ffffff;
			position: relative;
		}
		&Item:not(:last-child) {
			margin-bottom: 15px;
		}
	}
	&:hover {
		.sideCategoryNav__child {
			padding: 15px 30px;
			width: 300px;
			max-height: 999px;
			z-index: 10;
			overflow: visible;
			@include mq-down {
				width: 100%;
			}
			&::before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				background-color: #222222;
				transform: rotate(45deg);
				position: absolute;
				left: -5px;
				top: 25px;
				opacity: 1;
				@include mq-down {
					display: none;
				}
			}
		}
	}
  }
  &__link {
    display: flex;
    align-items: center;
    padding: 15px 15px 15px 70px;
    @include fontsize(13);
    font-weight: 700;
    letter-spacing: -0.1em;
    height: 66px;
  }
//   &__child {
//     position: absolute;
//     left: 100%;
//     top: 0%;
//     width: 0;
//     opacity: 0;
//     // transition: 0.3s;
//     background: #222222;
//     z-index: 100;
//     @include mq-down {
//       width: 100%;
//       max-height: 0;
//       overflow: hidden;
//       transition: max-height 0.3s;
//     }
//     &.is-visible {
//       max-width: 99999px;
//       width: 300px;
//       padding: 15px 30px;
//       opacity: 1;
//       @include mq-down {
//         position: static;
//         width: 100%;
//         max-height: 999px;
//       }
//       &::before {
//         content: '';
//         display: block;
//         width: 20px;
//         height: 20px;
//         background-color: #222222;
//         transform: rotate(45deg);
//         position: absolute;
//         left: -5px;
//         top: 25px;
//         @include mq-down {
//           display: none;
//         }
//       }
//     }
//     &Item {
//       &:not(:last-child) {
//         margin-bottom: 15px;
//       }
//     }
//     a {
//       display: block;
//       color: #ffffff;
//     }
//   }
}